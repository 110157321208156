import * as authActions from '../../actions/authActions/constants'
import {deleteLoginCookies, getCookie} from "../../../helpers/cookies";

const initialState = {
    loggedIn: true,
    user_id: getCookie('user_id'),
    is_email_confirmed: getCookie('is_email_confirmed'),
    processed: false,
    accessToken: getCookie('accessToken'),
    user_public_id: getCookie('user_public_id'),
    user_name: getCookie('user_name'),
    isAuthorized: false,
    refreshToken: '',
    role: getCookie('role')
};

export default (state = initialState, action) => {
    switch (action.type) {
        case authActions.USER_SIGN_IN_REQUEST:
            return {
                ...state,
                processed: true
            };
        case authActions.USER_SIGN_IN_SUCCESS:
            return {
                ...state,
                user_name: action.payload.user_name?.first_name + ' ' + action.payload.user_name?.last_name,
                role: action.payload.role,
                loggedIn: true,
                processed: false,
                user_id: action.payload.public_id,
                user_public_id: action.payload.public_id,
                accessToken: action.payload.access_token
            }
        case authActions.USER_SIGN_IN_FAILURE:
            return {
                ...state,
                processed: false
            };
        case authActions.USER_VERIFY_OTP_REQUEST:
            return {
                ...state,
                processed: true,
            };
        case authActions.USER_VERIFY_OTP_SUCCESS:
            return {
                ...state,
                processed: false
            };
        case authActions.USER_VERIFY_OTP_FAILURE:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_SIGN_OUT_REQUEST:
            return {
                ...state,
                processed: true,
            };
        case authActions.USER_SIGN_OUT_SUCCESS:
            return {
                ...state,
                loggedIn: false,
                user_id: '',
                is_2fa_enabled: false,
                processed: false,
                accessToken: '',
                isAuthorized: false,
                refreshToken: '',
                user_name: '',
                user_public_id: ''
            };
        case authActions.USER_SIGN_OUT_FAILURE:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_SEND_RESET_EMAIL_REQUEST:
            return {
                ...state,
                processed: true,
            };
        case authActions.USER_SEND_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_SEND_RESET_EMAIL_FAILURE:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_SET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                processed: true,
            };
        case authActions.USER_SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_SET_NEW_PASSWORD_FAILURE:
            return {
                ...state,
                processed: false,
            };
        case authActions.USER_REFRESH_TOKENS:
            return {
                ...state,
                accessToken: action.payload.access_token,
                refreshToken: action.payload.refresh_token
            };
        default:
            return state
    }
}