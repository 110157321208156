import * as optionsActions from '../../actions/optionsActions/constants'

const initialState = {
    userOptions: null,
    transactionsOptions: null,
    currencies: null,
    airdropOptions: null,
    stakingOptions: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case optionsActions.GET_OPTIONS_REQUEST:
            return {
                ...state
            };
        case optionsActions.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                userOptions: {
                    user_role: action.payload.user_role,
                    user_status: action.payload.user_status,
                },
                transactionsOptions: {
                    payment_method: action.payload.payment_method,
                    payment_status: action.payload.payment_status,
                    payment_type: action.payload.payment_type,
                    promo_status: action.payload.promo_status
                },
                airdropOptions: {
                    airdrop_order_status: action.payload.airdrop_order_status,
                    airdrop_status: action.payload.airdrop_status
                },
                stakingOptions: {
                    deposit_status: action.payload.deposit_status,
                    duration_unit: action.payload.duration_unit,
                    payment_status: action.payload.payment_status,
                    program_status: action.payload.program_status,
                    yield_payment: action.payload.yield_payment
                }
            };
        case optionsActions.GET_OPTIONS_FAILURE:
            return {
                ...state
            };
        case optionsActions.GET_CURRENCIES_REQUEST:
            return {
                ...state
            };
        case optionsActions.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                currencies: action.payload,
            };
        case optionsActions.GET_CURRENCIES_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}