import * as settingsActions from '../../actions/settingsActions/constants'

const initialState = {
    wallets: null,
    chains: null,
    stages: null,
    isLoadingStages: true,
    isProceedStage: false,
    appSettings: null,
    isProceedAppSettings: false,
    isLoadingAppSettings: true,
    isLoadingDirectPay: true,
    isProceedDirectPay: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case settingsActions.GET_WALLETS_REQUEST:
            return {
                ...state,
                isLoadingDirectPay: true
            };
        case settingsActions.GET_WALLETS_SUCCESS:
            return {
                ...state,
                wallets: action.payload,
                isLoadingDirectPay: false
            };
        case settingsActions.GET_WALLETS_FAILURE:
            return {
                ...state,
                wallets: null,
                isLoadingDirectPay: false
            };
        case settingsActions.ADD_WALLET_REQUEST:
            return {
                ...state,
                isProceedDirectPay: true
            };
        case settingsActions.ADD_WALLET_SUCCESS:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.ADD_WALLET_FAILURE:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.UPDATE_WALLET_REQUEST:
            return {
                ...state,
                isProceedDirectPay: true
            };
        case settingsActions.UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.UPDATE_WALLET_FAILURE:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.DELETE_WALLET_REQUEST:
            return {
                ...state,
                isProceedDirectPay: true
            };
        case settingsActions.DELETE_WALLET_SUCCESS:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.DELETE_WALLET_FAILURE:
            return {
                ...state,
                isProceedDirectPay: false
            };
        case settingsActions.GET_CHAINS_REQUEST:
            return {
                ...state,
                isLoadingDirectPay: true
            };
        case settingsActions.GET_CHAINS_SUCCESS:
            return {
                ...state,
                chains: action.payload,
                isLoadingDirectPay: false
            };
        case settingsActions.GET_CHAINS_FAILURE:
            return {
                ...state,
                chains: null,
                isLoadingDirectPay: false
            };
        case settingsActions.GET_STAGES_REQUEST:
            return {
                ...state,
                isLoadingStages: true
            };
        case settingsActions.GET_STAGES_SUCCESS:
            return {
                ...state,
                stages: action.payload,
                isLoadingStages: false
            };
        case settingsActions.GET_STAGES_FAILURE:
            return {
                ...state,
                stages: null,
                isLoadingStages: false
            };
        case settingsActions.CREATE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.CREATE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.CREATE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.UPDATE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.UPDATE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.UPDATE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.OPEN_CLOSE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.OPEN_CLOSE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.OPEN_CLOSE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.GET_APP_SETTINGS_REQUEST:
            return {
                ...state,
                isLoadingAppSettings: true,
            };
        case settingsActions.GET_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                appSettings: action.payload,
                isLoadingAppSettings: false
            };
        case settingsActions.GET_APP_SETTINGS_FAILURE:
            return {
                ...state,
                appSettings: null,
                isLoadingAppSettings: false
            };
        case settingsActions.UPDATE_APP_SETTINGS_REQUEST:
            return {
                ...state,
                isProceedAppSettings: true
            };
        case settingsActions.UPDATE_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                isProceedAppSettings: false
            };
        case settingsActions.UPDATE_APP_SETTINGS_FAILURE:
            return {
                ...state,
                isProceedAppSettings: false
            };
        default:
            return state
    }
}