export const GET_STAGES_REQUEST = 'GET_STAGES_REQUEST';
export const GET_STAGES_SUCCESS = 'GET_STAGES_SUCCESS';
export const GET_STAGES_FAILURE = 'GET_STAGES_FAILURE';

export const CREATE_STAGE_REQUEST = 'CREATE_STAGE_REQUEST';
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS';
export const CREATE_STAGE_FAILURE = 'CREATE_STAGE_FAILURE';

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST';
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS';
export const UPDATE_STAGE_FAILURE = 'UPDATE_STAGE_FAILURE';

export const OPEN_CLOSE_STAGE_REQUEST = 'OPEN_CLOSE_STAGE_REQUEST';
export const OPEN_CLOSE_STAGE_SUCCESS = 'OPEN_CLOSE_STAGE_SUCCESS';
export const OPEN_CLOSE_STAGE_FAILURE = 'OPEN_CLOSE_STAGE_FAILURE';

export const GET_APP_SETTINGS_REQUEST = 'GET_APP_SETTINGS_REQUEST';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAILURE = 'GET_APP_SETTINGS_FAILURE';

export const UPDATE_APP_SETTINGS_REQUEST = 'UPDATE_APP_SETTINGS_REQUEST';
export const UPDATE_APP_SETTINGS_SUCCESS = 'UPDATE_APP_SETTINGS_SUCCESS';
export const UPDATE_APP_SETTINGS_FAILURE = 'UPDATE_APP_SETTINGS_FAILURE';

export const GET_WALLETS_REQUEST = 'GET_WALLETS_REQUEST';
export const GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS';
export const GET_WALLETS_FAILURE = 'GET_WALLETS_FAILURE';

export const ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAILURE = 'ADD_WALLET_FAILURE';

export const UPDATE_WALLET_REQUEST = 'UPDATE_WALLET_REQUEST';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';
export const UPDATE_WALLET_FAILURE = 'UPDATE_WALLET_FAILURE';

export const DELETE_WALLET_REQUEST = 'DELETE_WALLET_REQUEST';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_FAILURE = 'DELETE_WALLET_FAILURE';

export const GET_CHAINS_REQUEST = 'GET_CHAINS_REQUEST';
export const GET_CHAINS_SUCCESS = 'GET_CHAINS_SUCCESS';
export const GET_CHAINS_FAILURE = 'GET_CHAINS_FAILURE';

export const DELETE_STAGE_REQUEST = 'DELETE_STAGE_REQUEST';
export const DELETE_STAGE_SUCCESS = 'DELETE_STAGE_SUCCESS';
export const DELETE_STAGE_FAILURE = 'DELETE_STAGE_FAILURE';