export const GET_BONUSES_LIST_REQUEST = 'GET_BONUSES_LIST_REQUEST';
export const GET_BONUSES_LIST_SUCCESS = 'GET_BONUSES_LIST_SUCCESS';
export const GET_BONUSES_LIST_FAILURE = 'GET_BONUSES_LIST_FAILURE';

export const GET_BONUS_REQUEST = 'GET_BONUS_REQUEST';
export const GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS';
export const GET_BONUS_FAILURE = 'GET_BONUS_FAILURE';

export const UPDATE_BONUS_REQUEST = 'UPDATE_BONUS_REQUEST';
export const UPDATE_BONUS_SUCCESS = 'UPDATE_BONUS_SUCCESS';
export const UPDATE_BONUS_FAILURE = 'UPDATE_BONUS_FAILURE';

export const CREATE_BONUS_REQUEST = 'CREATE_BONUS_REQUEST';
export const CREATE_BONUS_SUCCESS = 'CREATE_BONUS_SUCCESS';
export const CREATE_BONUS_FAILURE = 'CREATE_BONUS_FAILURE';

export const GET_BONUSES_TRANSACTIONS_LIST_REQUEST = 'GET_BONUSES_TRANSACTIONS_LIST_REQUEST';
export const GET_BONUSES_TRANSACTIONS_LIST_SUCCESS = 'GET_BONUSES_TRANSACTIONS_LIST_SUCCESS';
export const GET_BONUSES_TRANSACTIONS_LIST_FAILURE = 'GET_BONUSES_TRANSACTIONS_LIST_FAILURE';

export const DELETE_BONUS_TRANSACTION_REQUEST = 'DELETE_BONUS_TRANSACTION_REQUEST';
export const DELETE_BONUS_TRANSACTION_SUCCESS = 'DELETE_BONUS_TRANSACTION_SUCCESS';
export const DELETE_BONUS_TRANSACTION_FAILURE = 'DELETE_BONUS_TRANSACTION_FAILURE';
export const CLEAR_BONUS = 'CLEAR_BONUS';

